import {useEffect} from 'react';
import {
    Grid
} from '@material-ui/core';
import TextField from "../../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../../components/FormInputs/DateTimeField/DateTimeField";
import PlanInfoSmartDropDown from "../../common/PlanInfoSmartDropDown/PlanInfoSmartDropDown";
import MembershipTypeSmartDropDown from "../../common/MembershipTypeSmartDropDown/MembershipTypeSmartDropDown";

const StepTwo = (props) => {
    const {data, stepNum, hasError, getErrorMessage, onChange} = props;
    const {planType, membershipType, oneTimeFee, monthlyFee, totalPayment, membershipEffectiveDate} = data[stepNum];
    const {dependents} = data[0];

    useEffect(() => {
        let priority = 0;
        let memType = 'I'; 
        const length = dependents.value.length;
        for(let i = 0; i < length; i++) {
            if(dependents.value[i].relationship.membershipPriority > priority) {
                priority = dependents.value[i].relationship.membershipPriority;
                memType = dependents.value[i].relationship.membershipType;
            }
        }
        onChange('membershipType', memType, 1);
    }, [dependents]);


    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <PlanInfoSmartDropDown 
                    label="Plan"
                    value={planType.value}
                    onChange={val => {
                        if(val) {
                            onChange('oneTimeFee', val.fee, 1);
                            onChange('monthlyFee', val.monthlyPayment, 1);
                            onChange('monthsPay', val.monthsPay, 1);
                            onChange('totalPayment', ( val.fee + (val.monthlyPayment * val.monthsPay) ).toFixed(2), 1);
                        }
                        else {
                            onChange('oneTimeFee', '', 1);
                            onChange('monthlyFee', '', 1);
                            onChange('monthsPay', '', 1);
                            onChange('totalPayment', '', 1);
                        }
                        onChange('planType', val, 1)
                    }}
                    required={planType.required}
                    error={hasError('planType')}
                    disabled={false}
                    helperText={getErrorMessage('planType')}
                    membershipTypeId={membershipType.value} 
                />
            </Grid>
            <Grid item xs={6}>
                <MembershipTypeSmartDropDown 
                    label="Membership Type"
                    value={membershipType.value}
                    onChange={val => onChange('membershipType', val)}
                    required={membershipType.required}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    label="One-Time Fee"
                    value={oneTimeFee.value}
                    onChange={val => onChange('oneTimeFee', val)}
                    required={oneTimeFee.required}
                    error={hasError('oneTimeFee')}
                    disabled={true}
                    helperText={getErrorMessage('oneTimeFee')}
                    isNumericOnly
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    label="Monthly Fee"
                    value={monthlyFee.value}
                    onChange={val => onChange('monthlyFee', val)}
                    required={monthlyFee.required}
                    error={hasError('monthlyFee')}
                    disabled={true}
                    helperText={getErrorMessage('monthlyFee')}
                    isNumericOnly
                />
            </Grid>
            <Grid item xs={4}>
                <TextField 
                    label="Total Payment"
                    value={totalPayment.value}
                    onChange={val => onChange('totalPayment', val)}
                    required={totalPayment.required}
                    error={hasError('totalPayment')}
                    disabled={true}
                    helperText={getErrorMessage('totalPayment')}
                    isNumericOnly
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    label="Membership Effective Date"
                    fullWidth={true}
                    value={membershipEffectiveDate.value}
                    onChange={date => onChange('membershipEffectiveDate', date)}
                    required={membershipEffectiveDate.required}
                    error={hasError('membershipEffectiveDate')}
                    disabled={false}
                    helperText={getErrorMessage('membershipEffectiveDate')}
                    disablePast={true}
                />
            </Grid>
        </Grid>
    )
}

export default StepTwo