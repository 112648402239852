import './App.css'
import React, {Fragment, useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import routes from "../../config/Routes"
import Navigation from "../../components/Navigation/Navigation";

const App = (props) => {

  return <Fragment>
    <Router>
      <Navigation routes={routes}>
        <Switch>
          {
            routes.map(route => {
              return <Route exact path={route.path} key={route.linkName}>{route.component}</Route>
            })
          }
        </Switch>
      </Navigation>
    </Router>
  </Fragment>
}

export default App;
