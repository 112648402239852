import {
    Grid,
    Button
} from '@material-ui/core';
import {COLOR_PALETTE} from "../../../config/colorPalette";

const ConfirmationPage = props => {
    const {data} = props;

    return <Grid container style={{padding: '20px'}}
            alignItems="center"
            direction="column"
        >
            <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
                <h2>Congratulations you are an Orange Member!</h2>
            </Grid>
            <Grid item xs={12}><hr/></Grid>
            <Grid item xs={12}>
                <h3>Now you can enjoy all Orange Medical Plan benefits.</h3>            
            </Grid>
            <Grid item xs={12}>
                <h4>Your Member ID is "{data.membershipId}"</h4>
            </Grid>
            <Grid item xs={12}>
                <h4>You will receive an email with all membership detail information</h4>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="secondary" onClick={() => window.location.reload()}>
                    Home
                </Button>
            </Grid>
        </Grid>
}

export default ConfirmationPage;