const createPalette = (color) => {
    const colorHexValue = color.replace('#', '')
    const colorIntValue = parseInt(colorHexValue, 16)
    const red = (colorIntValue >> 16) & 255;
    const green = (colorIntValue >> 8) & 255;
    const blue = colorIntValue & 255;

    return {
        opacity25: `rgba(${red}, ${green}, ${blue}, 0.25)`,
        opacity50: `rgba(${red}, ${green}, ${blue}, 0.5)`,
        opacity75: `rgba(${red}, ${green}, ${blue}, 0.75)`,
        opacity100: `rgba(${red}, ${green}, ${blue}, 1)`
    }
}

export const COLOR_PALETTE = {
    green: createPalette('#255c09'),
    orange: createPalette('#fea412'),
    red: createPalette('#f44336')
}

