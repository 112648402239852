import {useState, useEffect} from 'react';
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import {getMembershipType} from "../../../apiCalls/getMembershipType";
import axios from 'axios';
import PropTypes from "prop-types";
import Notification from "../../../components/Notification/Notification";
import { membershipTypeOptions } from '../../../constants/apiResponseConstants';

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const MembershipTypeSmartDropDown = props => {

    const {label, value, onChange, required, disabled, error, helperText} = props;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)
        getMembershipType(source)
        .then(response => {            
            setOptions(response.map(d => {
                return {
                    label: d.membershipTypeDesc,
                    value: d.membershipTypeId
                }
            }));
            setLoading(false);
        })
        .catch(error => {
            // hardcoding the membership type options in case if the api fails
            setOptions(membershipTypeOptions);
            setLoading(false);
            // if (axios.isCancel(error)) {
            //     console.log("Cancelled membership type options fetching");
            // } else {
            //     console.log('Failed to fetch membership type options', error);
            //     setLoading(false);
            //     setNotification({
            //         open: true,
            //         type: "error",
            //         message: "Failed to fetch membership type options, please try again"
            //     });
            // }
        });

        return () => source.cancel();
    }, []);

    return <>
                <AutocompleteDropdown
                    label={label}
                    options={options}
                    value={value}
                    loading={loading}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                />
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </>
}

MembershipTypeSmartDropDown.propTypes = {
    label: PropTypes.string.isRequired, 
    value: PropTypes.string.isRequired, 
    onChange: PropTypes.func.isRequired, 
    required: PropTypes.bool,
    disabled: PropTypes.bool, 
    error: PropTypes.bool, 
    helperText: PropTypes.string
}

export default MembershipTypeSmartDropDown;

