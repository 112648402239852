import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    IconButton
 } from "@material-ui/core";
import {Delete, Edit, AddCircle, ExpandLess, ExpandMoreRounded} from "@material-ui/icons";

const NativeButton = props => {
    const {onClick, label, disabled, size, type} = props;

    const upDownButton = type === 'up' ? 
        <IconButton edge="end" aria-label="up" disabled={disabled} onClick={onClick}>
            <ExpandLess />
        </IconButton> : type === 'down' ? 
        <IconButton edge="end" aria-label="down" disabled={disabled} onClick={onClick}>
            <ExpandMoreRounded />
        </IconButton> : null;

    const addButton = label ?
        <Button variant="outlined"
                color="primary"
                size={size}
                onClick={onClick}
                disabled={disabled}
                style={{marginTop: '3px'}}
        >
            <AddCircle fontSize="small"/>&nbsp;
            {label}
        </Button>
        :
        <IconButton onClick={onClick}
                    aria-label="add"
                    disabled={disabled}
                    style={{marginTop: '-3px'}}
        >
            <AddCircle fontSize={size}/>
        </IconButton>;

    const editButton = label ?
        <Button variant="outlined"
            color="primary"
            size={size}
            onClick={onClick}
            disabled={disabled}
            style={{marginTop: '3px'}}
        >
            <AddCircle fontSize="small"/>&nbsp;
            {label}
        </Button>
        :
        <IconButton onClick={onClick}
            aria-label="edit"
            disabled={disabled}
            style={{marginTop: '-3px'}}
        >
            <Edit fontSize={size}/>
        </IconButton>;

    const deleteButton = label ?
        <Button variant="outlined"
                color="primary"
                size={size}
                onClick={onClick}
                disabled={disabled}
                style={{marginTop: '3px'}}
        >
            <Delete fontSize="small"/>&nbsp;
            {label}
        </Button>
        :
        <IconButton onClick={onClick}
                    disabled={disabled}
                    aria-label="delete"
                    style={{marginTop: '-3px'}}
        >
            <Delete fontSize={size}/>
        </IconButton>;

    const defaultButton = <Button variant="outlined"
                               color="primary"
                               size={size}
                               onClick={onClick}
                               disabled={disabled}
                               style={{marginTop: '6px'}}
    >
        {label}
    </Button>;

    const renderButton = () => {
        if(type === 'add'){
            return addButton;
        }
        else if(type === 'edit'){
            return editButton;
        }
        else if(type === 'delete'){
            return deleteButton;
        }
        else if(upDownButton){
            return upDownButton;
        }
        else{
            return defaultButton;
        }
    }

    return renderButton();
};

NativeButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool
};

export default NativeButton;
