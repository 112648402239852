import axios from "../config/axios";
import environment from "../config/environment";

export const getMembershipType = (source) => {
    return new Promise((resolve, reject) => {
        axios.get(`${environment().functionsURL}membership-type`,
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data && response.data.length) {
                        resolve(response.data);
                    }
                    else {
                        resolve([]);
                    }
                }
                reject(response);
            })
            .catch(error => reject(error))
    })
}

