import axios from "../../config/axios";
import environment from "../../config/environment";

export const submitApplication = (source, application) => {
    return new Promise((resolve, reject) => {
        axios.post(`${environment().functionsURL}application`,
            {
                ...application
            },
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200 && response.data) {
                    resolve(response.data);
                }
                else reject(response);
            })
            .catch(error => reject(error))
        })
}