import React from 'react'
import {
    Home,
} from '@material-ui/icons'
import NewApplication from "./../features/Application/NewApplication/NewApplication";

const routes = [
    {
        linkName: 'Home',
        path: '/',
        component: <NewApplication />,
        showInNavigation: false,
        icon: <Home />
    }
]

export default routes