import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";

const CustomNumberFormat = props => {
    const {inputRef, onChange, onlyInteger, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({target: {value: values.floatValue}});
            }}
            isNumericString
            allowNegative={false}
            isAllowed={values => {
                if(typeof onlyInteger === 'boolean' && onlyInteger && values.floatValue && !Number.isInteger(values.floatValue)) return false;
                return true;
            }}
        />
    );
}

CustomNumberFormat.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CustomNumberFormat;
