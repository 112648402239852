export const checkCVVValidity = (cardNumber, cvv) => {
    const americanExpressCardNumber = /^3[47][0-9]{13}$/;
    const americanExpressCVV = /^[0-9]{4}$/; 
    const restCVV = /^[0-9]{3}$/; 

    if(americanExpressCardNumber.test(cardNumber)) {
        return americanExpressCVV.test(cvv);
    }
    else return restCVV.test(cvv);

}