import {
    Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const Notification = props => {
    const {open, onClose, message, type} = props;

    return <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
                <MuiAlert 
                    elevation={6}
                    variant="filled"
                    severity={type}
                    onClose={onClose}
                >
                    {message}
                </MuiAlert>
            </Snackbar>
}

Notification.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    message: PropTypes.string,
    type: PropTypes.oneOf(['success', 'warning', 'info', 'error'])
}

export default Notification;