export const genderOptions = [{
    label: "Female",
    value: "F"
}, {
    label: "Male",
    value: "M"
}];

export const centerOptions = [{
    label: "Hialeah",
    value: "1001"
},
{
    label: "Miami Gardens",
    value: "1002"
},
{
    label: "South Miami",
    value: "1003"
},
{
    label: "Placeholder for missing Center",
    value: "1005"
},
{
    label: "GM Medical Office",
    value: "1008"
},
{
    label: "Coconut Creek",
    value: "1009"
},
{
    label: "Pembroke Pines",
    value: "1010"
},
{
    label: "Plantation",
    value: "1011"
},
{
    label: "Felicidad Medical Center",
    value: "1037"
},
{
    label: "Community Health Wellness Center, INC",
    value: "1046"
},
{
    label: "Clinica Bosco, INC",
    value: "1051"
},
{
    label: "Clinic Center, INC",
    value: "1052"
},
{
    label: "Garcia Saez Medical Group, LLC",
    value: "1053"
},
{
    label: "Personal Care Medical Group, LLC",
    value: "1054"
},
{
    label: "Gilberto Jimenez MD, INC",
    value: "1055"
},
{
    label: "Josepha L Binker MD, PA Offices",
    value: "1056"
},
{
    label: "Placeholder for second missing Center",
    value: "1063"
},
{
    label: "Little Havana",
    value: "1065"
},
{
    label: "Dr. Ramon Gutierrez MD, PA",
    value: "1066"
},
{
    label: "Vida Medical Center of Sweetwater",
    value: "1067"
},
{
    label: "Grace Medical Clinic",
    value: "1069"
},
{
    label: "The Nova Cosmetic and Rehabilitation Center",
    value: "1070"
},
{
    label: "Perfect Legs Vein Specialist, LLC",
    value: "1071"
},
{
    label: "Kendall Lakes Medical Clinic",
    value: "1072"
},
{
    label: "Vida Medical Group, PA",
    value: "1073"
},
{
    label: "Dr. Cesar Figueroa MD, PA",
    value: "1074"
},
{
    label: "Primary and Urgent Care",
    value: "1075"
},
{
    label: "Del Prado Medical Center",
    value: "1076"
},
{
    label: "Lehigh Medical & Research Center",
    value: "1077"
},
{
    label: "Life Plus Health Care Group, INC",
    value: "1078"
},
{
    label: "Kendall",
    value: "1079"
},
{
    label: "Lauderdale Lakes",
    value: "1080"
}];

export const relationshipOptions = [{
    label: "Daughter",
    value: {
        membershipPriority: 3,
        relationshipTypeDesc: "Daughter",
        relationshipTypeId: "D",
        membershipType: "F"
    }
},
{
    label: "Son",
    value: {
        membershipPriority: 3,
        relationshipTypeDesc: "Son",
        relationshipTypeId: "N",
        membershipType: "F"
    }
}, {
    label: "Other Dependant",
    value: {
        membershipPriority: 3,
        relationshipTypeDesc: "Other Dependant",
        relationshipTypeId: "O",
        membershipType: "F"
    }
},
{
    label: "Spouse",
    value: {
        membershipPriority: 2,
        relationshipTypeDesc: "Spouse",
        relationshipTypeId: "S",
        membershipType: "C"
    }
}];

export const paymentTypeOptions = [{
    label: "Cash",
    value: "A"
},
{
    label: "Need to be corrected",
    value: "B"
},
{
    label: "Credit Card",
    value: "C"
},
{
    label: "Check",
    value: "H"
},
{
    label: "ACH",
    value: "T"
}];

export const planInfoOptions = [{
    label: "Dental",
    value: {
        planDesc: "Dental",
        planInfoId: 185,
        planTypeId: "D",
        membershipTypeId: "I",
        fee: 10,
        monthsPay: 2,
        monthlyPayment: 19.99,
        stripeProductId: null
    }
}, {
    label: "Medical",
    value: {
        planDesc: "Medical",
        planInfoId: 186,
        planTypeId: "M",
        membershipTypeId: "I",
        fee: 30,
        monthsPay: 2,
        monthlyPayment: 49.99,
        stripeProductId: null
    }
}, {
    label: "Hospital",
    value: {
        planDesc: "Hospital",
        planInfoId: 187,
        planTypeId: "H",
        membershipTypeId: "I",
        fee: 20,
        monthsPay: 2,
        monthlyPayment: 24.99,
        stripeProductId: null
    }
}, {
    label: "Dental",
    value: {
        planDesc: "Dental",
        planInfoId: 188,
        planTypeId: "D",
        membershipTypeId: "C",
        fee: 10,
        monthsPay: 2,
        monthlyPayment: 24.99,
        stripeProductId: null
    }
}, {
    label: "Medical",
    value: {
        planDesc: "Medical",
        planInfoId: 189,
        planTypeId: "M",
        membershipTypeId: "C",
        fee: 30,
        monthsPay: 2,
        monthlyPayment: 55.99,
        stripeProductId: null
    }
}, {
    label: "Hospital",
    value: {
        planDesc: "Hospital",
        planInfoId: 190,
        planTypeId: "H",
        membershipTypeId: "C",
        fee: 20,
        monthsPay: 2,
        monthlyPayment: 24.99,
        stripeProductId: null
    }
}, {
    label: "Dental",
    value: {
        planDesc: "Dental",
        planInfoId: 191,
        planTypeId: "D",
        membershipTypeId: "F",
        fee: 10,
        monthsPay: 2,
        monthlyPayment: 29.99,
        stripeProductId: null
    }
}, {
    label: "Medical",
    value: {
        planDesc: "Medical",
        planInfoId: 192,
        planTypeId: "M",
        membershipTypeId: "F",
        fee: 30,
        monthsPay: 2,
        monthlyPayment: 59.99,
        stripeProductId: null
    }
}, {
    label: "Hospital",
    value: {
        planDesc: "Hospital",
        planInfoId: 193,
        planTypeId: "H",
        membershipTypeId: "F",
        fee: 20,
        monthsPay: 2,
        monthlyPayment: 24.99,
        stripeProductId: null
    }
}, {
    label: "Dental+Hospital",
    value: {
        planDesc: "Dental+Hospital",
        planInfoId: 194,
        planTypeId: "X",
        membershipTypeId: "I",
        fee: 20,
        monthsPay: 2,
        monthlyPayment: 44.98,
        stripeProductId: null
    }
}, {
    label: "Dental+Hospital",
    value: {
        planDesc: "Dental+Hospital",
        planInfoId: 195,
        planTypeId: "X",
        membershipTypeId: "C",
        fee: 20,
        monthsPay: 2,
        monthlyPayment: 49.98,
        stripeProductId: null
    }
}, {
    label: "Dental+Hospital",
    value: {
        planDesc: "Dental+Hospital",
        planInfoId: 196,
        planTypeId: "X",
        membershipTypeId: "F",
        fee: 20,
        monthsPay: 2,
        monthlyPayment: 54.98,
        stripeProductId: null
    }
}, {
    label: "Medical+Hospital",
    value: {
        planDesc: "Medical+Hospital",
        planInfoId: 197,
        planTypeId: "Z",
        membershipTypeId: "I",
        fee: 30,
        monthsPay: 2,
        monthlyPayment: 74.98,
        stripeProductId: null
    }
}, {
    label: "Medical+Hospital",
    value: {
        planDesc: "Medical+Hospital",
        planInfoId: 198,
        planTypeId: "Z",
        membershipTypeId: "C",
        fee: 30,
        monthsPay: 2,
        monthlyPayment: 80.98,
        stripeProductId: null
    }
}, {
    label: "Medical+Hospital",
    value: {
        planDesc: "Medical+Hospital",
        planInfoId: 199,
        planTypeId: "Z",
        membershipTypeId: "F",
        fee: 30,
        monthsPay: 2,
        monthlyPayment: 84.98,
        stripeProductId: null
    }
}];

export const membershipTypeOptions = [{
    label: "Couple",
    value: "C"
},
{
    label: "Family",
    value: "F"
},
{
    label: "Individual",
    value: "I"
}];
