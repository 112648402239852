import React from 'react';
import {
    Dialog,
    DialogContent,
    makeStyles
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    dialogContent:{
        padding:'0px',
        height: 'auto',
        maxHeight: props => props.size === 'xl' ? 'none' : '500px',
        overflow: 'hidden',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        }       
    },
    paper: {
        overflowY: 'inherit'
    },
    closeBtn: {
        position: 'absolute',
        right: '-40px',
        color: '#C7C9C7',
        fontSize: '36px',
        opacity: 0.8,
        '&:hover' : {
            opacity: 1
        }
    }
});

const NativeDialogBox = props => {
    const classes = useStyles(props);

    const {openDialog, onCloseBtnClicked, size, noCloseBtn, dialogTemplate, data} = props;

    const renderTemplate = (child) => {
        return React.cloneElement(child,
            {
                data: data
            }
        );
    }

    return <Dialog 
                open={openDialog} 
                onClose={onCloseBtnClicked} 
                maxWidth={size || "md"} 
                fullWidth={true} 
                classes={{paper: classes.paper}}
            >
                {
                    noCloseBtn === true ? 
                    null : 
                    <Close classes={{root: classes.closeBtn}} onClick={onCloseBtnClicked}/>
                }
                <DialogContent classes={{root: classes.dialogContent}}>
                    {renderTemplate(dialogTemplate)}
                </DialogContent>
            </Dialog>
}

NativeDialogBox.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    onCloseBtnClicked: PropTypes.func.isRequired,
    dialogTemplate: PropTypes.element.isRequired,
    size: PropTypes.string,
    noCloseBtn: PropTypes.bool
}

export default NativeDialogBox;

