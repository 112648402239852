import React, {useState, Fragment} from 'react';
import {
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    IconButton
} from '@material-ui/core';
import {ExpandMore, DeleteForever} from '@material-ui/icons';
import Button from "../../../components/FormInputs/Button/Button";
import UpsertDependentForm from "./UpsertDependentForm";
import DialogBox from "../../../components/DialogBox/DialogBox";
import moment from 'moment';
import PropTypes from 'prop-types';

const TABLE_HEADER = ['First Name', 'Last Name', 'DOB', 'Relationship', 'Gender', 'Phone', 'Delete']

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    marginLeft: '-30px'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tableRow: {
      '&:hover': {
          cursor: 'pointer'
      }
  }
}));

const ManageDependents = props => {

    const {dependents, onChange} = props;

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [showUpsertDependentDialog, setShowUpsertDependentDialog] = useState(false);
    const [updateDependentFormValues, setUpdateDependentFormValues] = useState(null);
    const [updateDependentIndex, setUpdateDependentIndex] = useState(-1);

    const populateUpdateForm = (index, dependentObject) => {
        setUpdateDependentFormValues(dependentObject);
        setShowUpsertDependentDialog(true);
        setUpdateDependentIndex(index);
    }

    const upsertDependent = (newDependent, isUpdateMode) => {
        if(isUpdateMode) {
            onChange([
                ...dependents.slice(0, updateDependentIndex),
                newDependent,
                ...dependents.slice(updateDependentIndex+1)
            ])
        }
        else {
            onChange([
                ...dependents,
                {...newDependent}
            ])    
        }
        closeAndReset()
    }

    const closeAndReset = () => {
        setShowUpsertDependentDialog(false);
        setUpdateDependentFormValues(null);
        setUpdateDependentIndex(-1);
    }

    return <Fragment>
                <DialogBox 
                    openDialog={showUpsertDependentDialog}
                    onCloseBtnClicked={closeAndReset}
                    dialogTemplate={<UpsertDependentForm />}
                    data={{
                        upsertDependent: upsertDependent,
                        dependent: updateDependentFormValues
                    }}
                />
                <Accordion expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel-content"
                        id="panel-header"
                    >
                        <Typography className={classes.heading}><strong>Dependent Information</strong></Typography>
                        <Typography className={classes.secondaryHeading}>{`${dependents.length} dependent(s)`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={1}>
                                <Button 
                                    type="add"
                                    onClick={() => setShowUpsertDependentDialog(true)}
                                    size="small"
                                    label="Add"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                        <TableRow>
                                        {
                                            TABLE_HEADER.map(header => <TableCell key={header}><strong>{header}</strong></TableCell>)
                                        }
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                            dependents.map((dependent, dependentIndex) => {
                                                return <TableRow 
                                                            key={dependentIndex}
                                                            className={classes.tableRow}
                                                            onDoubleClick={() => populateUpdateForm(dependentIndex, dependent)}
                                                        >
                                                            <TableCell component="th" scope="row">{dependent.firstName}</TableCell>
                                                            <TableCell>{dependent.lastName}</TableCell>
                                                            <TableCell>{moment(dependent.dateOfBirth).format('MM/DD/YYYY')}</TableCell>
                                                            <TableCell>{dependent.relationship.relationshipTypeDesc}</TableCell>
                                                            <TableCell>{dependent.gender}</TableCell>
                                                            <TableCell>{dependent.phone}</TableCell>
                                                            <TableCell>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        onChange([
                                                                            ...dependents.slice(0, dependentIndex),
                                                                            ...dependents.slice(dependentIndex+1)
                                                                        ])
                                                                    }}
                                                                >
                                                                    <DeleteForever/>
                                                                </IconButton>         
                                                            </TableCell>
                                                        </TableRow>
                                            })
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
}

ManageDependents.propTypes = {
    onChange: PropTypes.func.isRequired,
    dependents: PropTypes.arrayOf(PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        dateOfBirth: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        relationship: PropTypes.object,
        gender: PropTypes.string,
        phone: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        memberId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        applicationId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])
    }))
}

export default ManageDependents;
