export const checkCreditCardValidity = (creditCardNumber) => {
    const americanExpress = /^3[47][0-9]{13}$/; 
    const visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/; 
    const masterCard = /^(?:5[0-9]{15})$/; 
    const discover = /^(?:6[0-9]{15})$/; 
    const dinnersClub = /^3[068][0-9]{12}$/;

    return americanExpress.test(creditCardNumber) || 
            visa.test(creditCardNumber) || 
            masterCard.test(creditCardNumber) || 
            discover.test(creditCardNumber) || 
            dinnersClub.test(creditCardNumber);
}