const local = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "http://localhost:3000"
}

const dev = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-agent-webapp.azurewebsites.net"
}

const test = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-agent-webapp.azurewebsites.net"
}

const prod = {
    functionsURL: "https://prod-omp-functionapp.azurewebsites.net/api/",
    azureADRedirectURI: "https://prod-omp-standalone-webapp.azurewebsites.net"
}

const environment = () => {
    switch(process.env.REACT_APP_ENVIRONMENT) {
        case 'PROD':
            return prod;
        case 'TEST':
            return test;
        case 'DEV':
            return dev;
        case 'LOCAL':
            return local;
        default:
            return dev;
    }
};

export default environment;