import React from 'react';
import { 
  makeStyles, 
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Grid
} from '@material-ui/core';
import companyLogo from "../../assets/media/companyLogo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff'
  },
  content: {
    overflowY: 'hidden',
    marginTop: 65,
    width: '100%'
  }
}));

const Navigation = (props) => {
  const classes = useStyles();
  const {children} = props

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" noWrap>
                <img src={companyLogo} alt="Company Logo" height="40" />
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
}

export default Navigation;
