import {FormControlLabel, Checkbox} from '@material-ui/core';
import PropTypes from 'prop-types';

const CheckboxField = props => {
    const {value, onChange, name, label, disabled, required, size} = props;

    return <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={event => onChange(event.target.checked)}
                        name={name}
                        color="secondary"
                        disabled={disabled}
                        required={required}
                        size={size}
                    />
                }
                label={label}
            />
}

CheckboxField.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium'])
}

export default CheckboxField;