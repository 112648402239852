import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    chipsFont: {
        color: 'black'
    },
    listboxScrollBar: {
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        }
    }
})

const NativeAutocompleteDropdown = props => {
    const { label, options, multiple, onChange, value, onInputChange, loading, valueIndexes,
        disabled, renderOption, required, error, helperText, optionLabelKey, optionValueKey } = props;
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(multiple ? [] : (valueIndexes ? {} : ""));
    const [labelKey, setLabelKey] = useState("label");
    const [valueKey, setValueKey] = useState("value");
    const classes = useStyles(props);

    useEffect(() => {
        if (optionLabelKey) setLabelKey(optionLabelKey);
    }, [optionLabelKey])

    useEffect(() => {
        if (optionValueKey) setValueKey(optionValueKey);
    }, [optionValueKey])

    useEffect(() => {
        let opt;
        if (multiple) {
            opt = [];
            if (valueIndexes && valueIndexes.length) {
                opt = valueIndexes.map(vI => {
                    return options[vI];
                })
            }
            else if (!valueIndexes) {
                opt = value.map(v => options.find(o => o[valueKey] === v));
            }
        }
        else {
            opt = {};
            if (valueIndexes && valueIndexes.length) {
                opt = options[valueIndexes[0]];
            }
            else if (!valueIndexes) {
                opt = options.find(o => o[valueKey] === value);
                if (!opt) opt = "";
            }
        }
        setSelectedOption(opt);
    }, [value, options, multiple, valueKey, valueIndexes])

    const otherProps = {};

    if (typeof renderOption === "function") {
        otherProps.renderOption = (opt) => renderOption(opt);
    }

    return <Autocomplete
                id={`asynchronous-autocomplete-${label}`}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(event, val) => {
                    if (multiple) {
                        onChange(val.map(v => v[valueKey]))
                    }
                    else {
                        onChange(val ? val[valueKey] : (valueIndexes ? null : ""))
                    }
                    setSelectedOption(val)
                }}
                onInputChange={(event, inputVal) => {
                    if (onInputChange) {
                        onInputChange(inputVal)
                    }
                }}
                getOptionLabel={option => {
                    return option && option[labelKey] ? option[labelKey] : ""
                }}
                renderOption={option => option.label}
                options={options}
                loading={loading}
                loadingText={`Loading ${label} options`}
                multiple={multiple}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label={label}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                )
                            }}
                            InputLabelProps={{
                                margin: 'dense'
                            }}
                            inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                            required={required}
                            error={error}
                            helperText={helperText}
                        />
                    )
                }}
                popupIcon={<ExpandMore />}
                classes={{ listbox: classes.listboxScrollBar }}
                ChipProps={{
                    classes: { root: classes.chipsFont }
                }}
                value={ value ? (options.find(o => {
                    if(selectedOption && selectedOption.value) return o.value === selectedOption.value
                    return false;
                }) || value) : null }
                disabled={disabled}
                size="small"
                {...otherProps}
                style={{ margin: '-8px 0px' }}
            />    
}

NativeAutocompleteDropdown.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    options: (props, propName, componentName) => {
        const keys = [props['optionLabelKey'] || "label", props['optionValueKey'] || "value"].sort();
        let error = false;
        props[propName].some(opt => {
            if (JSON.stringify(Object.keys(opt).sort()) !== JSON.stringify(keys)) {
                error = true;
                return error === true; //break the loop
            }
        })
        if (error) return new Error(`Array prop ${propName} must have item objects in correct shape in ${componentName}`);
    },
    options: (props, propName, componentName) => {
        let error = false;
        props[propName].some(opt => {
            if (typeof opt.value !== "string") {
                if (!props['valueIndexes']) {
                    error = true;
                    return error === true;
                }
            }
        });
        if (error) return new Error(`If value of option item in prop array ${propName} (for e.g. options[0]['valueKey']) is not of string type, 
        prop array valueIndexes is required`);
    },
    /*
    if prop multiple = true,
    onChange function prop should be able to receive one array param - value
    value will be an array of selected options values
    else 
    onChange function prop should be able to receive one string param - value
    value will be selected option's value
    */
    onChange: PropTypes.func.isRequired,
    /*
    if prop multiple = true,
    value prop must be an array
    else 
    value prop must be a string
    */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    value: (props, propName, componentName) => {
        if (props['multiple'] && !Array.isArray(props[propName])) {
            return new Error(`Required prop ${propName} must be an array if prop multiple is true in ${componentName}`);
        }
        if (!props['multiple']) {
            if (props['valueIndexes'] && typeof props[propName] !== "object") {
                return new Error(`Required prop ${propName} must be an object if prop multiple is false and option item in prop options array 
                    (i.e. options[0]['valueKey']) is of type object in ${componentName}`);
            }
            else if (!props['valueIndexes'] && typeof props[propName] !== "string") {
                return new Error(`Required prop ${propName} must be a string if prop multiple is false and option item in prop options array 
                (i.e. options[0]['valueKey']) is of type string in ${componentName}`);
            }
        }
    },
    /*
    If value of option item in prop options array (for e.g. options[0]['valueKey']) is not of type String and is of type Object, 
    comparison can become difficult
    Comparison is required find option item in options array that matches to prop value. This helps with pre-populating dropdown
    with passed down selections (using prop value).
    So we require implemntor to pass valueIndexes prop array that has indexes of selected values inside prop options array
    */
    valueIndexes: PropTypes.arrayOf(PropTypes.number),
    loading: PropTypes.bool.isRequired,
    multiple: PropTypes.bool,
    /*
    prop function onInputChange should be able to receive one param - inputVal
    inputVal reflects changes in input value
    Implementor can use this function to call API and fetch options based on user input keyword
    Bydefault, all options are loaded at once and user can search through them
    */
    onInputChange: PropTypes.func,
    disabled: PropTypes.bool,
    /*
    prop function renderOption must be able to receive one param - option
    option object will have the same shape as an item of options array prop
    Implementor can use this function to render option in different way by returning
    a ReactNode
    */
    renderOption: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    /*
    props optionLabelKey = "label" and optionValueKey = "value" by default
    If implementer has option object in options prop array in different shape, these props
    can be used
    For e.g. for options array prop having shape - 
    [
        {
            clientGroupID: 'MARIO',
            clientGroupName: 'Mario'
        }
    ]
    implementer can use these props -
    optionLabelKey = "clientGroupName"
    optionValueKey = "clientGroupID" 
    so that he doesn't have to change his options array
    */
    optionLabelKey: PropTypes.string,
    optionValueKey: PropTypes.string
}

export default NativeAutocompleteDropdown;